import React, { useReducer, useRef } from 'react';
import { theme, styled } from '../../Theme';
import { SearchIcon, MenuIcon, CartIcon, ProfileIcon } from '../Icons';
import { useAppSettingsData } from '../AppSettingsProvider/AppSettingsProvider';
import loadable from '@loadable/component';
import menuReducer from '../../Shared/Models/Headers/DesktopHeader/reducers/menuReducer';
import { useKexNavigate } from '../../Shared/KexRouter/KexRouter';
import { GetCart } from '../../Cart/Cart';
import { useUserStateData } from '../UserContextProvider/UserContextProvider';
import useEncodedCurrentUser from '../Hooks/useEncodedCurrentUser';
import { CommerceMode } from '../../Enums/CommerceMode.enum';

const NavigationMenu = loadable(() =>
  import('../NavigationMenu/NavigationMenu')
);

type PropTypes = {
  toggleModalState: () => void;
  toggleSearchState: () => void;
  setUserMenuId: (value: number) => void;
};

function MobileMenu({
  toggleModalState,
  toggleSearchState,
  setUserMenuId,
}: PropTypes) {
  const [{ menuIsOpen, mountMenu }, menuDispatch] = useReducer(menuReducer, {
    menuIsOpen: false,
    mountMenu: true,
    menuId: null,
  });

  const {
    commerceMode,
    translations: {
      'common/menu': menuText,
      'common/search': searchText,
      'common/close': closeText,
      'common/partner': partner,
      'common/cart': cartLabel,
    },
    staticPages: { checkoutPage },
  } = useAppSettingsData();
  const { authenticated } = useUserStateData();
  const id = useEncodedCurrentUser();
  const kexNavigate = useKexNavigate();

  const goToCheckoutAction = () => {
    !isEmpty && kexNavigate(checkoutPage);
  };
  const { cart, isLoading } = GetCart(id);
  const nbrRef = useRef<HTMLSpanElement>(null);
  const isEmpty = cart.numberOfItems === 0;

  return (
    <>
      {!menuIsOpen && (
        <Menu>
          <List>
            <li>
              {!menuIsOpen && (
                <ButtonLeft
                  aria-label={menuText}
                  onClick={() =>
                    menuDispatch(
                      menuIsOpen
                        ? { type: 'closeMenu' }
                        : { type: 'mountAndOpen' }
                    )
                  }
                >
                  <ProfileContainer>
                    <StyledMenuIcon isOpen={menuIsOpen} />
                    {!menuIsOpen ? menuText : closeText}
                  </ProfileContainer>
                </ButtonLeft>
              )}
            </li>
            <li>
              <ButtonCenter aria-label={searchText} onClick={toggleSearchState}>
                <ProfileContainer>
                  <StyledSearchIcon /> {searchText}
                </ProfileContainer>
              </ButtonCenter>
            </li>
            {(commerceMode === CommerceMode.B2B ||
              commerceMode === CommerceMode.B2B_AND_B2C) && (
              <li>
                {!menuIsOpen && authenticated ? (
                  <ButtonRight onClick={goToCheckoutAction}>
                    <ProfileContainer>
                      <StyledCartIcon />
                      {cartLabel}
                      {!isLoading && !isEmpty && (
                        <CartItemsNotifier>
                          <span ref={nbrRef}>{cart.numberOfItems}</span>
                        </CartItemsNotifier>
                      )}
                    </ProfileContainer>
                  </ButtonRight>
                ) : (
                  <ButtonRight onClick={toggleModalState}>
                    <ProfileContainer>
                      <Profile /> {partner}
                    </ProfileContainer>
                  </ButtonRight>
                )}
              </li>
            )}
          </List>
        </Menu>
      )}
      {mountMenu && (
        <NavigationMenu
          isOpen={menuIsOpen}
          onMenuClose={() => menuDispatch({ type: 'closeMenu' })}
          toggleModalState={toggleModalState}
          setUserMenuId={setUserMenuId}
        />
      )}
    </>
  );
}

const buttonStyling = {
  display: 'flex',
  alignItems: 'center',
  minWidth: theme.spacing(8),
  minHeight: theme.spacing(8),
  border: {
    xy: {
      width: 0,
    },
  },
};

const ProfileContainer = styled.div({
  fontSize: theme.alpha,
  color: theme.blueDark,
  margin: { xy: '0' },
  lineHeight: theme.lineHeight.snug,
});
const Profile = styled(ProfileIcon, {
  width: theme.spacing(3.5),
  height: theme.spacing(4),
  fill: theme.blueDark,
  margin: { xy: 'auto', top: theme.spacing(0.5) },
});

const Menu = styled.div({
  position: 'fixed',
  bottom: theme.spacing(6),
  backgroundColor: theme.white,
  maxWidth: theme.mobileMenuMaxWidth,
  width: '100%',
  left: '50%',
  transform: 'translateX(-50%)',
  borderRadius: theme.spacing(8),
  zIndex: theme.zIndex.MobileMenu,
  padding: {
    x: theme.spacing(8),
    y: theme.spacing(2),
  },
  boxShadow:
    '0 2px 32px 2px rgba(59, 72, 80, 0.05), 0 0 4px 0 rgba(72, 72, 72, 0.15)',
});

const List = styled.ul({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  maxHeight: '100%',
});

const ButtonLeft = styled.button({
  ...buttonStyling,
  justifyContent: 'flex-end',
});

const ButtonCenter = styled.button({
  ...buttonStyling,
  justifyContent: 'flex-end',
});

const ButtonRight = styled.button({
  ...buttonStyling,
  justifyContent: 'flex-end',
});

const StyledSearchIcon = styled(SearchIcon, {
  width: theme.spacing(4),
  height: theme.spacing(4),
  fill: theme.blueDark,
  margin: { xy: 'auto', top: theme.spacing(0.5) },
});

const StyledMenuIcon = styled(MenuIcon, {
  width: theme.spacing(4),
  height: theme.spacing(4),
  fill: theme.blueDark,
  color: theme.blueDark,
  margin: { xy: 'auto', top: theme.spacing(0.5) },
});

const StyledCartIcon = styled(CartIcon, {
  width: theme.spacing(3),
  height: theme.spacing(4),
  fill: theme.blueDark,
  margin: { xy: 'auto', top: theme.spacing(0.5) },
});

const CartItemsNotifier = styled.span({
  position: 'absolute',
  marginTop: theme.spacing(4),
  marginRight: theme.spacing(8),
  top: `-${theme.spacing(4)}px`,
  right: `-${theme.spacing(2.5)}px`,
  background: {
    color: theme.secondary,
  },
  padding: {
    x: theme.spacing(2),
    y: theme.spacing(1),
  },
  borderRadius: '50%',
  fontSize: theme.alpha,
  fontWeight: 'bold',
  minWidth: theme.spacing(2),
  minHeight: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflowY: 'hidden',
});

export default MobileMenu;
