import React, { useState, useEffect, useRef } from 'react';
import { theme, styled } from '../../Theme';
import { useAppSettingsData } from '../AppSettingsProvider/AppSettingsProvider';
import { SearchIcon } from '../Icons';
import useOutsideClick from '../Hooks/useOutsideClick';
import { useKexNavigate } from '../KexRouter/KexRouter';
import ContentDivider from '../../Shared/Divider/Divider';
import { debounce } from '../Common/debounce';
import { FetchSuggestions } from '../../SearchPage/Search';
import { ChipsButton } from '../Button/Button';
import { pushDataLayer } from '../../../Features/utils/dataLayer.lib';

type PropTypes = {
  toggle: () => void;
  query: string;
  setQuery: (query: string) => void;
};
function MobileQuickSearch({ toggle, query, setQuery }: PropTypes) {
  const {
    translations: {
      'search/placeholder': placeholderText,
      'search/suggestedKeywords': suggestedKeywords,
    },
    staticPages: { searchPage },
  } = useAppSettingsData();

  const kexNavigate = useKexNavigate();

  const [suggestions, setSuggestions] = useState<string[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  useOutsideClick(wrapperRef, () => {
    toggle();
  });

  useEffect(() => {
    if (query.length >= 3) {
      debounce(() => {
        FetchSuggestions(query, searchPage, setSuggestions);
      }, 200);
    }
  }, [query, searchPage, setSuggestions]);

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  }, []);

  const suggestPressed = (suggestValue: string) => {
    pushDataLayer({
      event: 'qsSuggestedKeywordClick',
      qsSearchTerm: inputRef.current?.value,
      suggestedKeyword: suggestValue,
    });
    setQuery(suggestValue);
    doSearch(suggestValue);
  };

  const doSearch = (query: string) => {
    if (query.length > 0) {
      toggle();
      kexNavigate(`${searchPage}?searchQuery=${query}`);
      setQuery('');
      return;
    }
  };
  const inputFieldChanged = async (e: any) => {
    setQuery(e.currentTarget.value);
  };

  return (
    <>
      <Wrapper ref={wrapperRef}>
        <Container>
          <InputWrapper>
            <InputField
              onChange={e => inputFieldChanged(e)}
              onKeyDown={e => e.key === 'Enter' && doSearch(query)}
              ref={inputRef}
              defaultValue={query}
              type="search"
              placeholder={placeholderText}
            />
            <StyledSearchButton onClick={() => doSearch(query)}>
              <StyledSearchIcon />
            </StyledSearchButton>
          </InputWrapper>
        </Container>

        {suggestions.length > 0 && (
          <>
            <CommonKeywords>{suggestedKeywords}</CommonKeywords>
            <Divider />
            <ButtonContainer>
              {suggestions.map(
                (suggestion: string) =>
                  suggestion && (
                    <Button
                      key={suggestion}
                      onClick={() => suggestPressed(suggestion)}
                    >
                      {suggestion}
                    </Button>
                  )
              )}
            </ButtonContainer>
          </>
        )}
      </Wrapper>
    </>
  );
}

const Wrapper = styled.div({
  position: 'fixed',
  left: theme.none,
  top: theme.mobileHeaderHeight,
  backgroundColor: theme.white,
  width: '100%',
  height: '100%',
  zIndex: theme.zIndex.CartMobile,
  padding: {
    xy: theme.spacing(4),
  },
  transition: {
    duration: theme.timings.threeTenths,
    property: 'transform',
  },
  boxShadow: '0 6px 6px -6px rgba(72, 72, 72, 0.3)',
  border: {
    top: {
      width: theme.tiny,
      color: theme.placeholder,
    },
  },
});

const Divider = styled(ContentDivider, {
  maxWidth: theme.spacing(16),
  height: theme.spacing(0.5),
  margin: { x: 'auto', top: theme.spacing(1), bottom: theme.spacing(4) },
});

const CommonKeywords = styled.p({
  textAlign: 'center',
  marginTop: theme.spacing(8),
  fontWeight: 'bold',
  fontSize: theme.gamma,
});

const ButtonContainer = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'center',
});

const Button = styled(ChipsButton, {
  color: theme.blueDark,
  margin: { xy: theme.spacing(1) },
});

const StyledSearchButton = styled.button({
  width: theme.gamma,
  height: theme.gamma,
  margin: { right: theme.spacing(4) },
  display: 'flex',
  alignSelf: 'center',
  fill: theme.black,
  left: 0,
});

const Container = styled.div({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  top: '50%',
  left: '50%',
  width: '100%',
});

const StyledSearchIcon = styled(SearchIcon, {
  width: theme.spacing(4),
  height: theme.spacing(4),
  fill: theme.black,
  left: 0,
});

const InputField = styled.input({
  flexGrow: 1,
  backgroundColor: theme.white,
  flexShrink: 1,
  fontSize: theme.gamma,
  fontWeight: 'bold',
  marginRight: theme.spacing(2),
  color: theme.black,
  padding: { left: theme.spacing(4), y: theme.spacing(4) },
  ':focus': {
    outline: { style: 'none' },
  },
});

const InputWrapper = styled.div({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  border: { xy: { width: theme.tiny, color: theme.black } },
  backgroundColor: theme.white,
  WebkitAppearance: 'none',
});

export default MobileQuickSearch;
