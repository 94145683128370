import React, { useEffect, useState } from 'react';
import { styled, theme } from '../../../../Theme';
import { CloseIcon, Logo } from '../../../Icons';
import MobileMenu from '../../../MobileMenu/MobileMenu';
import KexLink from '../../../KexLink/KexLink';
import { useAppSettingsData } from '../../../AppSettingsProvider/AppSettingsProvider';
import Modal from '../../../Modal/Modal';
import LoginModalContent from '../../../../Login/LoginModalContent';
import MobileQuickSearch from '../../../../Shared/MobileQuickSearch/MobileQuickSearch';
import { useUserStateData } from '../../../UserContextProvider/UserContextProvider';
import NotificationBanner from '../DesktopHeader/NotificationBanner';
import {
  hideNotificationModal,
  shouldShowNotificationModal,
} from '../../../Notification/shouldShowNotificationModal';
import { NotificationModal } from '../../../NotificationModal/NotificationModal';
import { pushDataLayer } from './../../../../../Features/utils/dataLayer.lib';
type PropTypes = {
  setInnerRef: (ref: HTMLElement | null) => void;
};

function MobileHeader({ setInnerRef }: PropTypes) {
  const { siteRoute } = useAppSettingsData();
  const [showModal, toggleModal] = useState(false);
  const {
    notification,
    notificationId,
    popupTitle,
    popupContent,
    popupImage,
  } = useUserStateData();
  const {
    translations: { 'account/signIn': signInLabel },
    staticPages: { registrationPage },
  } = useAppSettingsData();

  const toggleModalState = (id?: number) => {
    toggleModal(!showModal);
    id && setUserMenuId(id);
  };

  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [userMenuId, setUserMenuId] = useState<number>();
  const [showNotification, setShowNotification] = useState(
    notificationId && shouldShowNotificationModal(notificationId)
  );

  useEffect(() => {
    setShowNotification(
      notificationId && shouldShowNotificationModal(notificationId)
    );
  }, [notificationId]);
  const toggleSearchState = () => {
    !showSearch && pushDataLayer({ event: 'qsClick' });
    setShowSearch(!showSearch);
  };
  const closeNotification = () => {
    hideNotificationModal(notificationId);
    setShowNotification(false);
  };
  useEffect(() => {
    if (showSearch) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'initial';
    }
  }, [showSearch]);
  return (
    <>
      {showSearch && (
        <MobileQuickSearch
          toggle={toggleSearchState}
          query={query}
          setQuery={setQuery}
        />
      )}
      {showModal && (
        <Modal
          overlay
          toggle={toggleModalState}
          isUserAction
          hasCloseButton
          isClosedOnOutsideClick={false}
        >
          <LoginModalContent
            registerUrl={registrationPage}
            closeModal={toggleModalState}
            title={signInLabel}
          />
        </Modal>
      )}
      {notification && <NotificationBanner content={notification} />}
      {showNotification && (
        <NotificationModal
          popupTitle={popupTitle}
          closeNotification={closeNotification}
          popupImage={popupImage}
          popupContent={popupContent}
        />
      )}
      <HeaderTag ref={setInnerRef}>
        <StyledKexLink href={`/${siteRoute}`}>
          <StyledLogo />
        </StyledKexLink>
        {showSearch && <Close />}
      </HeaderTag>
      <MobileMenu
        toggleModalState={toggleModalState}
        toggleSearchState={toggleSearchState}
        setUserMenuId={setUserMenuId}
      />
    </>
  );
}

export default MobileHeader;

const Close = styled(CloseIcon, {
  width: theme.spacing(4),
  height: theme.spacing(4),
  margin: { xy: theme.spacing(4) },
  fill: theme.black,
  right: theme.none,
  position: 'absolute',
});

const HeaderTag = styled.header({
  width: '100%',
  display: 'flex',
  backgroundColor: theme.mediumAqua,
  zIndex: theme.zIndex.HeaderMobile,
  height: theme.mobileHeaderHeight,
  transition: {
    duration: theme.timings.threeTenths,
    property: 'all',
  },
  top: 0,
  position: 'sticky',
});

const StyledKexLink = styled(KexLink, {
  margin: {
    x: 'auto',
    y: theme.tiny,
  },
  display: 'flex',
  alignItems: 'center',
});

const StyledLogo = styled(Logo, {
  height: '24px',
  width: '64px',
});
